.index {
    position: absolute;
    overflow-y: scroll;
}

.section__title {
    color: var(--light-color);
    position: relative;
    font-weight: 300;
    font-size: 2rem;
    letter-spacing: 0.75px;
}

.heading {
    position: relative;
}

.navArrow {
    position: relative;
    margin-bottom: 2rem;
}

.navArrow > a {
    animation: fadeDown 1s ease forwards;
    animation-delay: 1s;
    opacity: 0;
}

.navArrow > a > svg {
    width: 3em;
    animation: bounceDown infinite 1.75s;
    animation-delay: 2s;
}

@keyframes fadeDown {
    0% {
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
}
    
@keyframes bounceDown {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(5px);
    }
    65% {
      transform: translateY(3px);
    }
}

@media (min-width: 768px) {
    .section__title {
        font-size: 2.5rem;
    }
}

@media (min-width: 1100px) {
    .section__title {
        font-size: 3rem;
    }
}