.sidemenu-popup-content {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    right: 0;
    background-color: var(--primary-color);
    height: 100vh;
    max-width: 100vw;
    width: 100vw;
    transform: translateX(100vw);
    animation: slideInRight 0.5s ease forwards;
    border: transparent;
    outline: transparent;
    border-radius: 0px;
    padding: 20px;
}

.sidemenu-popup-content > .modal {
    align-items: center;
    margin-top: 25vh;
}

.sideMenu__content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.sideMenu__content > a {
    color: var(--light-color);
    font-size: 2rem;
    width: fit-content;
    transition: box-shadow .33s cubic-bezier(0.5,0,0,0.75);
}

.sideMenu__content > a:hover {
    box-shadow: 0 2px 0 0 var(--light-color);
}

.sidemenu__button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: transparent;
    outline: transparent;
    border: transparent;
    cursor: pointer;
    padding: 10px 20px;
}

.sidemenu__close {
    position: fixed;
    top: 0;
    right: 0;
    cursor: pointer;
    padding-top: 15px;
    padding-right: 20px;
}

@keyframes slideInRight {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
      transform: translateX(0px);
    }
  }