#about {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: flex-end;
    height: 90vh;
}

.about__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 90%;
}

.section__about {
    position: relative;
    display: grid;
    overflow-y: scroll;
    max-height: 61vh;
}

