.navbar-top {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    transition: all ease-out 0.5s;
    height: 38px;
}

.navbar-on {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--primary-color);
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    transition: all ease-out 0.5s;
    height: 38px;
}

.navbar__name {
    position: relative;
}

.navbar__name > a {
    font-weight: 400;
    color: var(--light-color);
    text-transform: uppercase;
    letter-spacing: 0.02rem;
}

.navbar__name > a > img {
    width: 85px;
}

.navbar__right {
    position: relative;
    display: none;
    gap: 2rem;
}

.navbar__right > a {
    font-weight: 400;
    color: var(--light-color);
    letter-spacing: 0.05rem;
    opacity: 90%;
    transition: box-shadow .33s cubic-bezier(0.5,0,0,0.75);
}

.navbar__right > a:hover {
    box-shadow: 0 2px 0 0 var(--light-color);
}

@media (min-width: 768px) {
    .navbar {
        padding: 20px 20px;
    }

    .navbar__right {
        display: flex;
    }
}

@media (min-width: 1100px) {
    .navbar {
        padding: 20px 20px;
    }

    .navbar__right {
        display: flex;
    }
}