.cc_row_1 {
    display: flex;
    gap: 1.5rem;
    justify-content: space-between;
    flex-direction: column;
}

.contact_content {
    margin-top: 20px;
}

input {
    background-color: transparent;
    border: 1px solid var(--light-color);
    outline: none;
    color: var(--light-color);
    line-height: 1.25;
    padding: 15px;
    width: 100%;
    margin-top: 0px;
    font-size: 1.2rem;
    font-family: 'Ubuntu';
    font-weight: 300;
}

textarea {
    background-color: transparent;
    border: 1px solid var(--light-color);
    outline: none;
    color: var(--light-color);
    margin-top: 40px;
    padding: 1.25rem 0.75rem;
    font-size: 1.2rem;
    width: 100%;
    height: 170px;
    font-family: 'Ubuntu';
    font-weight: 300;
    overflow: auto;
    resize: vertical;
}

.cc_row_3 > input {
    background-color: var(--primary-color);
    color: var(--light-color);
    border: 2px solid var(--light-color);
    cursor: pointer;
    padding: 4px 20px;
    font-weight: 300;
    font-size: 1.1rem;
    transition: all 0.6s cubic-bezier(0.55, 0, 0.1, 1);
    width: auto;
    margin-top: 30px;
}

.cc_row_3 > input:hover {
    background-color: var(--light-color);
    color: var(--primary-color);
}

.submit-popup-content {
    background-color: var(--primary-color);
    border: 1px solid var(--light-color);
    color: var(--light-color);
    line-height: 1.25;
    padding: 15px 30px;
    font-size: 1.2rem;
    font-family: 'Ubuntu';
    font-weight: 300;
    width: fit-content;
    max-width: 60vw;
    text-align: center;
}

.submit__close {
    position: absolute;
    top: 5px;
    right: 5px;
}

@media (min-width: 768px) {
    input {
        width: 344px;
    }
    .cc_row_1 {
        flex-direction: row;
        gap: 2rem;
    }
}

@media (min-width: 1100px) {
    input {
        width: 300px;
    }
    .cc_row_1 {
        flex-direction: row;
    }
}